<template>
  <div class="pl-8 pr-8">
    <div class="ml-3 mr-3">
      <v-row>
        <v-col class="mt-14 pl-0 pr-0 text-left" cols="12">
          <!-- <h1 class="help__title-text mb-7">help</h1> -->
          <!-- <v-layout justify-end>
            <img
              width="200"
              style="margin-left: -10px"
              class="mb-4"
              src="../../assets/img/helpN.png"
            />
          </v-layout> -->
          <p class="help__subtitle mb-4">PADRINO MAGICO</p>
        </v-col>
      </v-row>
    </div>

    <v-row>
      <v-col cols="2">
        <v-card elevation="0" outlined style="border-radius: 15px; height: 95%">
          <p class="py-3" style="color: #3957b9">
            <strong>LISTA DE CHAT</strong>
          </p>

          <v-list nav dense>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title >BRM</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
               <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>DAVIVIENDA</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="10">
        <div
          v-if="listMessage.length == 0"
          style="
            text-align: center;
            padding: 20px 30px;
            height: 500px;
            background-color: rgb(255 255 255);
            border-radius: 15px;
            border: 1px solid #e6e4e8;
          "
        >
          <img
            alt="Imagen BRM"
            width="150"
            class="mt-8 pt-8"
            src="../assets/img/brm.png"
          />
          <p class="help__subtitle-2 mb-1">
            <strong> ¡Hola, chatea conmigo! </strong>
          </p>
          <p class="help__subtitle-1 mb-4">
            Preguntame lo que necesites refente a temas laborales
          </p>
        </div>

        <div
          v-else
          style="
            text-align: initial;
            padding: 30px 50px;
            height: 500px;
            background-color: rgb(255 255 255);
            border-radius: 15px;
            border: 1px solid #e6e4e8;
            display: flex;
            align-items: center;
            justify-content: center;
            display: flex;
            align-items: end;
            overflow-y: scroll;
            max-height: 500px;
          "
        >
          <v-row>
            <v-col cols="12">
              <template v-for="data in listMessage">
                <div style="width: 100%" :key="data.text">
                  <v-layout justify-end v-if="data.user">
                    <v-card
                      outlined
                      elevation="0"
                      class="chat-user px-4 mt-4"
                      style="width: 80%; text-align: end"
                    >
                      {{ data.text }}
                    </v-card>
                  </v-layout>
                  <v-card
                    v-else
                    outlined
                    elevation="0"
                    class="chat-assent mt-4 px-4"
                    style="width: 80%"
                  >
                    <v-row align="end">
                      <v-col cols="2">
                        <v-avatar size="40" class="mr-3 mb-1">
                          <img
                            src="../assets/img/robot-cabeza.png"
                            alt="Foto de perfil"
                          />
                        </v-avatar>
                      </v-col>
                      <v-col cols="10">
                        {{ data.text }}
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </template>
            </v-col>
          </v-row>
        </div>
        <v-layout v-if="loadingSend" justify-end>
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </v-layout>

        <v-text-field
          outlined
          append-icon="mdi-send"
          @click:append="send()"
          class="mt-0"
          v-model="html"
          label="Escribe aquí tu pregunta"
          @keyup.enter="send()"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-overlay :value="loading">
      <v-progress-circular :size="120" color="#3957B9" indeterminate>
        Procesando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
export default {
  data() {
    return {
      selectedItem: null,
      loadingSend: false,
      listMessage: [],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ color: [] }, { background: [] }],
      ],
      html: "",
      html2: "",
      token: {
        prompt_tokens: "",
        completion_tokens: "",
      },
      loading: false,
      token1: null,
      name: "",
      infoToken: {
        document: "",
      },
    };
  },
  created() {
    this.getToken();
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");

      this.token1 = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    send() {
      this.listMessage.push({
        text: this.html,
        user: true,
        align: "end",
        color: "#466be",
      });

      var data = {
        cliente: "davivienda",
        //cliente: "brm",
        identidad:
          "Desarrolla un asistente virtual para la empresa BRM, mi objetivo es ayudar a los nuevos empleados a conocer la empresa y sus procesos. Uno de esos procesos es con el cliente Davivienda, BRM presta los servicios de contact center. Si en algún momento el usuario hace preguntas que no estén relacionadas con la compañía y con mi [contexto] mi función será redirigirlo al contexto original y responder a las preguntas que estén dentro del alcance de mis objetivos. como asistente siempre debe tener en cuenta el [contexto] y no salirse de el, en caso de que ya no hayan más preguntas orientadas al contexto, debes cerrar el chat de una manera muy amable",
        modelo: "padrino",
        //identidad:
        //  "Desarrolla un asistente virtual para la empresa BRM, mi objetivo es ayudar a los nuevos empleados a conocer la empresa y sus procesos, y brindar información sobre las políticas y procedimientos de la compañía. Si en algún momento el usuario hace preguntas que no estén relacionadas con la compañía y con mi [contexto] mi función será redirigirlo al contexto original y responder a las preguntas que estén dentro del alcance de mis objetivos. como asistente siempre debe tener en cuenta el [contexto] y no salirse de el, en caso de que ya no hayan más preguntas orientadas al contexto, debes cerrar el chat de una manera muy amable",
        pregunta: this.html,
      };
      this.html = "";
      this.loadingSend = true;
      Api.Chat()
        .sendChat(data)
        .then((resp) => resp.json())
        .then((res) => {
          this.loadingSend = false;
          this.listMessage.push({
            text: res.content,
            user: false,
            align: "initial",
            color: "#c7c7c7;",
          });
        })
        .catch((error) => {
          this.loadingSend = false;
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>
<style>
@import "./../assets/css/main.less";
.chat-user {
  background: #b9c9fe !important;
  padding: 7px !important;
  /* color: #fff !important; */
  border-radius: 10px !important;
}
.chat-assent {
  background: #e1e1e2 !important;
  padding: 7px !important;
  border-radius: 10px !important;
}
.help__title-text {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.help__subtitle {
  font-family: "RobotoLight";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #353535;
}
.help__subtitle-2 {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #466be4;
}
.help__subtitle-1 {
  font-family: "RobotoLight";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #353535;
}

.help__text {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 59px;
  color: #353535;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 40px;
}
.lds-ellipsis div {
  position: absolute;
  top: 17px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #3957b9;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
